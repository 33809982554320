import React, { useState, useRef } from 'react';
import { withRouter } from "react-router-dom";
import GridContainer from "../common/MuiKit/Grid/GridContainer";
import GridItem from "../common/MuiKit/Grid/GridItem";
import LoadingPanel from '../common/Grid/LoadingPanel';
import { withSnackbar } from 'notistack';
import EventListView from '../event/EventListView';
import LibraryListView from "../document/LibraryListView";
import MatterSummary from "../matter/MatterSummary";
import TaskListView from "../task/TaskListView";
import ConversationListView from "../conversation/ConversationListView";
import Header from "../common/Header";
import { findDOMNode } from 'react-dom';
import api from "../../util/api";
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { Card } from '@progress/kendo-react-layout';
import SearchResults from "./SearchResults";
import { Dialog as KendoDialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

const Home = (props) => {
    const [loading, setLoading] = useState(false);

    const [keyword, setKeyword] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [noResults, setNoResults] = useState(false);

    const resultsDialogRef = useRef();

    const handleClick = (ev) => {
        search();
    }

    const handleKeyPress = (ev) => {
        if (ev.key === 'Enter') {
            search();
        }
    }

    const handleClose = (dataItem) => {
        setSearchResults([]);
        if (dataItem) {
            props.history.push(`/files/${dataItem.matterId}`);
        }
    }

    const search = () => {
        setSearchResults([])
        if (keyword) {
            setLoading(true);
            api.get(`search/${keyword}`)
                .then((response) => {
                    if (!response || response.length === 0) {
                        setNoResults(true);
                    } else {
                        setSearchResults(response);
                    }
                }).catch((error) => {
                    console.error('error: ', error);
                }).finally(() => {
                    setLoading(false);
                    findDOMNode(resultsDialogRef.current).focus();
                })
        }
    }

    return (
        <GridContainer>
            <Header setLoading={setLoading} loading={loading} title="LLAMA Orde Home" />
            {/* <GridItem xs={12} lg={12}>
                <MatterSummary margin="35px 0 0 0" />
            </GridItem> */}
            <GridItem xs={12} lg={12}>
                <Card className='searchbox mt-3'>
                    <Input placeholder='Search' onKeyPress={handleKeyPress} value={keyword} onChange={({ value }) => setKeyword(value)} />
                    <Button icon='search' onClick={handleClick} />
                </Card>
            </GridItem>
            <GridItem xs={12} lg={4}>
                <TaskListView cardHeight='634px' />
            </GridItem>
            <GridItem xs={12} lg={8}>
                <ConversationListView cardHeight='634px' />
            </GridItem>
            <GridItem xs={12} lg={6}>
                <EventListView margin="35px 0" cardHeight='500px' />
            </GridItem>
            <GridItem xs={12} lg={3}>
                <LibraryListView isDocument="1" margin="35px 0" cardHeight='500px' />
            </GridItem>
            <GridItem xs={12} lg={3}>
                <LibraryListView cardHeight='500px' />
            </GridItem>

            {
                loading &&
                <LoadingPanel />
            }
            {
                !noResults && searchResults.length > 0 &&
                <SearchResults ref={resultsDialogRef} onClose={handleClose} data={searchResults} searchText={keyword} />
            }
            {
                noResults &&
                <KendoDialog onClose={() => setNoResults(false)} width={400} title='No results found'>
                    <p>The search '{keyword}' did not return any matches.</p>
                    <DialogActionsBar>
                        <button className="k-button" onClick={() => setNoResults(false)}>Ok</button>
                    </DialogActionsBar>
                </KendoDialog>
            }
        </GridContainer>
    )
}

export default withSnackbar(withRouter(Home));